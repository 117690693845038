import { useReCaptcha } from 'vue-recaptcha-v3'

export const useRecaptcha = () => {
  const recaptchaInstance = useReCaptcha()

  const generateToken = async (action: string): Promise<string | false> => {
    await recaptchaInstance?.recaptchaLoaded()
    const token = await recaptchaInstance?.executeRecaptcha(action)

    return token ?? false
  }

  const verifyToken = async (token: string): Promise<boolean> => {
    try {
      const res = await fetch(`/api/recaptcha/verify`, {
        method: 'POST',
        body: JSON.stringify({
          token: token,
        }),
      })

      if (res) {
        const data = await res.json()

        if (data) {
          return data
        }
      }

      return false
    } catch (e) {
      console.log(e)

      return false
    }
  }

  return { generateToken, verifyToken } as const
}
